/** @jsxImportSource theme-ui */
import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SEO from "../components/Seo";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// markup
const FaqsPage = ({ data }) => {
  const pageData = data.prismicFaqsPage.data;
  const image = getImage(pageData.image);
  return (
    <Layout>
      <SEO title="Frequently Asked Questions" />
      <div>
        <div
          sx={{
            display: ["block", "flex"],
            h1: {
              width: ["auto", "30%"],
            },
            "& > div": {
              width: ["auto", "65%"],
              "& img": { border: "1px solid black" },
            },
          }}
        >
          <h1>{pageData.title.text}</h1>
          <GatsbyImage image={image} alt={pageData.image.alt} />
        </div>
        <div sx={{ width: "100%", my: 5 }}>
          {pageData.faqs.map((item, index) => {
            return (
              <div
                className="faqItem"
                key={`faq${index}`}
                sx={{
                  display: ["block", "flex"],
                  flexDirection: "row",
                  mb: 4,
                  h3: { width: ["auto", "30%"], mb: 2 },
                  div: { width: ["auto", "65%"] },
                  p: { mt: [2, "18px"] },
                }}
              >
                <h3>{item.question.text}</h3>
                <div sx={{ fontSize: 3, color: "muted" }}>
                  <PrismicRichText field={item.answer.raw} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default withPrismicPreview(FaqsPage);

export const query = graphql`
  query faqsPageQuery {
    prismicFaqsPage {
      _previewable
      data {
        faqs {
          answer {
            richText
            text
            raw
          }
          question {
            raw
            richText
            text
          }
        }
        image {
          alt
          gatsbyImageData
        }
        title {
          raw
          richText
          text
        }
      }
      last_publication_date
    }
  }
`;
